<template>
    <div class="activies">
        <div class="select-container">
            <div class="select-item">
                <div class="select-item-title">{{ "年份" | language }}</div>
                <Select v-model="year" style="width: 165px" @on-change="handleChange">
                    <Option v-for="item in yearList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>

            <Input :placeholder="'搜索科创课题' | language" search v-model="keyword" @keyup.enter.native="handleSearchChange"
                @on-search="handleSearchChange" class="my-select-input" />
        </div>

        <div class="list-container">
            <div class="list" v-if="list.length">
                <div class="item" v-for="item in list" :key="item._id" @click="goTo(item)">
                    <div class="img-container">
                        <img class="item-img" alt="" v-real-img="item.cover.src" />
                        <Icon @click="goTo(item)" class="video-play-icon" type="logo-youtube" v-if="item.video" />
                    </div>
                    <div class="item-title">{{ item.title }}</div>
                    <div class="item-desc row3-ellipse">{{ item.desc }}</div>
                    <div class="item-content">
                        <span class="item-content-title">{{
                                item.postAt
                        }}</span>
                        <!-- <span class="item-content-time">{{item.beginAt}}-{{item.endAt}}</span> -->
                    </div>
                </div>
            </div>
            <div class="no-date" :class="{ 'show-no-data': isEmpty }" v-else>
                {{ "没有符合筛选条件的数据" | language }}~
            </div>
            <div class="flex"></div>
            <Page :total="total" :current="pageNum" :page-size="pageSize" @on-change="handlePageChange"
                style="margin: 40px 0 100px 0; color: rgba(0, 0, 0, 0.3)" show-total>
                <span class="ivu-page-total" style="font-size: 16px">{{ "共有" | language }} {{ total }}
                    {{ "条" | language }}</span>
            </Page>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            list: [],
            keyword: "",
            year: "all",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            yearList: [],
            isEmpty: false,
        };
    },
    mounted() {
        this.getYear();
        this.fetchData();
    },
    methods: {
        handleChange(val) {
            this.isEmpty = false;
            this.year = val == "all" ? "" : val;
            this.fetchData();
        },
        handleSearchChange(val) {
            this.isEmpty = false;
            this.keyword = val;
            this.fetchData();
        },
        async fetchData() {
            const year = this.year == 'all' ? "" : this.year;
            let res = await this.$http.get(
                `/information/research?keyword=${this.keyword}&year=${year}&pageSize=${this.pageSize}&pageNum=${this.pageNum - 1}`
            );
            this.list = res.result;
            this.total = res.count;
            if (res.result.length == 0) {
                this.isEmpty = true;
            }
        },
        //获取最近10年
        getYear() {
            let date = new Date();
            let year = date.getFullYear();
            this.yearList.push({ label: "全部", value: "all" });
            for (let i = 0; i < 10; i++) {
                this.yearList.push({ label: year - i, value: year - i });
            }
        },
        //分页
        handlePageChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
        goTo(item) {
            this.$router.push(`/base/info/project/detail/${item._id}`);
        },
    },
};
</script>

<style lang="less" scoped>
.activies {
    .select-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .select-item {
            display: flex;
            align-items: center;

            .select-item-title {
                margin-right: 10px;
                opacity: 0.6;
                font-size: 16px;
                color: #000;
                letter-spacing: 0;
                line-height: 29px;
            }
        }

        .my-select-input {
            width: auto;
        }
    }

    .list-container {
        display: flex;
        flex-direction: column;
        min-height: 1000px;

        .list {
            display: flex;
            flex-wrap: wrap;

            .item {
                width: 32%;
                margin-right: 2%;
                cursor: pointer;
                padding: 25px 40px;
                margin-top: 30px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);

                &:nth-child(3n) {
                    margin-right: 0%;
                }

                .img-container {
                    width: 100%;
                    height: 250px;
                    border-radius: 2px;
                    overflow: hidden;
                    position: relative;
                    flex-shrink: 1;

                    .item-img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                        object-fit: cover;
                        transition: all ease 0.3s;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .item-title {
                    margin: 10px 0;
                    text-align: left;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.9);
                    font-size: 22px;
                    line-height: 1;
                }

                .item-content {
                    margin-top: 10px;
                    text-align: right;
                    opacity: 0.3;
                    font-size: 14px;
                    color: #000000;
                }

                .item-desc {
                    text-align: left;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.6);
                    line-height: 24px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }

                .btn-group {
                    display: flex;
                    justify-content: flex-start;
                }
            }
        }

        .no-date {
            width: 200px;
            height: 100px;
            line-height: 100px;
            margin: 60px auto;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            visibility: hidden;
            opacity: 0;
        }

        .flex {
            flex: 1;
        }
    }
}

@media screen and (max-width: 960px) {
    .activies {
        .select-container {
            .my-select-input {
                width: 100%;
                margin-top: 10px;
            }
        }

        .list-container {
            .list {
                .item {
                    width: 100%;
                }
            }
        }
    }
}
</style>
<style>
.show-no-data {
    visibility: visible !important;
    opacity: 1 !important;
}
</style>
